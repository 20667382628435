/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-empty */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import Vue from 'vue'
import axios from '@axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import SockJS from 'sockjs-client'

const sock = new SockJS('https://socket.motivit.com:443/echo')

export default {

  namespaced: true,
  state: {
    notificationSockId: 'coc-n01',
    alertSockId: 'coc-dw01',
    shifts: [],
    shifts_raw: [],
    shift: null,
    tasksRaw: [],
    tasks: [],
    isTaskBar: false,
    isLoading: true,
    calendarOptions: [
      {
        id: null,
        class: 'show-all',
        label: 'Show All',
      },
      {
        id: '1',
        class: 'scheduled',
        label: 'Scheduled',
      },
      {
        id: '2',
        class: 'in-progress',
        label: 'In Progress',
      },
      {
        id: '3',
        class: 'completed',
        label: 'Completed',
      },
      // {
      //   id: '4',
      //   class: 'm-clock-in',
      //   label: 'Missed Clock-in',
      // },
      // {
      //   id: '5',
      //   class: 'm-clock-out',
      //   label: 'Missed Clock-out',
      // },
      {
        id: '6',
        class: 'open-shift',
        label: 'Open Shift',
      },
      {
        id: '7',
        class: 'c-client',
        label: 'Cancelled by Client',
      },
      {
        id: '8',
        class: 'c-caregiver',
        label: 'Cancelled by Caregiver',
      },
      // {
      //   id: '9',
      //   class: 'tentative-not-echeduled',
      //   label: 'Tentative/Not Scheduled',
      // },
      // {
      //   id: '10',
      //   class: 'pending-confirmation',
      //   label: 'Pending Confirmation',
      // },
      // {
      //   id: '11',
      //   class: 'attention-required',
      //   label: 'Attention Required',
      // },
    ],
    caregivers: [],
  },
  getters: {
  },
  mutations: {
    SET_TASKS_RAW: (state, payload) => state.tasksRaw = payload,
    SET_TASKS: (state, payload) => state.tasks = payload,
    SET_SHIFTS: (state, payload) => state.shifts = payload,
    SET_SHIFTS_RAW: (state, payload) => state.shifts_raw = payload,
    SET_SHIFT: (state, payload) => state.shift = payload,
    APPEND_TASKS: (state, payload) => {
      payload.forEach(task => state.tasks.push(task))
    },
    SET_ISTASKBAR: (state, payload) => state.isTaskBar = payload,
    SET_CAREGIVERS: (state, payload) => state.caregivers = payload,
    SET_LOADING: (state, payload) => state.isLoading = payload,
  },
  actions: {
    getShifts: async state => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'get-Shift',
        userId: JSON.parse(localStorage.userData).id,
      })
      formData.append('data', data)
      try {
        const response = await state.dispatch('request', formData)
        // eslint-disable-next-line no-shadow
        const shifts = response.data.response.data
        await shifts.forEach((shift, index) => {
          shifts[index].title = shift.caregiver_fullname ? shift.caregiver_fullname : 'Open Shift'
          // shifts[index].title = `${moment(shift.start_time, ['HH.mm']).format('ha')}-${moment(shift.end_time, ['HH.mm']).format('ha')} ${shift.caregiver_fullname}`
          // shifts[index].title = `${shift.id} ${moment(shift.start_time, ['HH.mm']).format('ha')}-${moment(shift.end_time, ['HH.mm']).format('ha')} ${shift.caregiver_fullname}`
        })
        state.commit('SET_SHIFTS', shifts.filter(f => !['4', '5', '9', '10', '11'].includes(f.status)))
        state.commit('SET_SHIFTS_RAW', shifts.filter(f => !['4', '5', '9', '10', '11'].includes(f.status)))
        await state.dispatch('getCaregiversInShifts')
        state.commit('SET_LOADING', false)
      } catch (error) {
        // console.log(error)
        state.commit('SET_LOADING', false)
        state.dispatch('popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: true,
          // timer: 3500,
          customClass: {
            confirmButton: 'btn-primary w-25',
          },
        })
      }
    },

    getCaregiversInShifts: async ctx => {
      const tempCaregivers = await Array.from(new Set(ctx.state.shifts.map(a => a.caregiver))).map(caregiver => ctx.state.shifts.find(a => a.caregiver === caregiver))
      const caregivers = await tempCaregivers.map(f => (f.caregiver !== '0' ? { id: f.caregiver, name: `${f.caregiver_firstname} ${f.caregiver_lastname}` } : { id: f.caregiver, name: 'Open Shift' }))
      ctx.commit('SET_CAREGIVERS', caregivers)
      // console.log('caregivers', caregivers)
    },

    request: async (state, payload) => {
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}clientCalendar/action`, payload,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
        return response
      } catch (error) {
        return error
      }
    },

    popupNotif: (ctx, payload) => {
      Swal.fire(payload)
    },

    initializeSockJs: (state, payload) => {
      sock.onopen = () => {
        // console.log('Connection established.')
      }

      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === payload.id) {
          state.dispatch('getSockMsg', data.msg)
        }
      }

      sock.onclose = () => {
      // console.warn('Connection closed.')
        SockJS.call(sock, sock.url)
      }
    },

    getSockMsg: (state, sockMsg) => {
      if (sockMsg) {
      // Trigger reload or something here
        // console.log(sockMsg)
        state.dispatch('getShifts')
      }
    },

    sendSockMsg: (state, payload) => {
      sock.send(JSON.stringify({ id: payload.id, msg: payload.sockMsg }))
    },
  },
}
